$(document).ready(function () {
    // Fade In Animation
    const callback = function(entries) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add("motion-safe:animate-fadeIn");
            } else {
                entry.target.classList.remove("motion-safe:animate-fadeIn");
            }
        });
    };
    const targets = document.querySelectorAll(".js-fadein");
    const observer = new IntersectionObserver(callback);

    targets.forEach(function(target) {
        target.classList.add("opacity-0");
        observer.observe(target);
    });

    //Navbar
    $(function () {
        $(document).scroll(function () {
            var $nav = $(".navbar");
            $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());

            if($(this).scrollTop() > $nav.height()) {
                $('.navbar .logonav').attr('src', '/assets/images/logo/logo-dark.png');
            } else {
                $('.navbar .logonav').attr('src', '/assets/images/logo/logo.png');
            }
        });
    });

    // Gallery
    $('.btn_gallery').click(function (e) {
        var e = $(this).data('id');
        var panel = $(this).parentsUntil(".product_panel");

        $(panel).find(".panel_gallery").each(function () {
            $(this).hide();
        });

        $("#" + e).show();
    });
});